import React, { useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsIcon from "@mui/icons-material/Directions";
import MenuIcon from "@mui/icons-material/Menu";
import { Zoom, Slide, Fade } from "react-awesome-reveal";
import TextField from "@mui/material/TextField";
import { db } from "../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import ReactStars from "react-stars";

const NavLinks = [
  {
    navIcon: CallIcon,
    navText: "Call Now",
    navLink: "tel:+919535532412",
    click: false,
  },
  {
    navIcon: CalendarMonthIcon,
    navText: "Add Review",
    click: true,
  },
  {
    navIcon: DirectionsIcon,
    navText: "Get Directions",
    navLink: "https://maps.app.goo.gl/LniwD5UKc5BhEGp38",
    click: false,
  },
];

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [reviewAddSection, setReviewAddSection] = useState(false);
  const [name, setName] = useState("");
  const [tattooUrl, setTattooUrl] = useState(
    "https://content3.jdmagicbox.com/comp/def_content/permanent-tattoo-artists/shutterstock-470804000-compressor-permanent-tattoo-artists-9-8s1hf.jpg"
  );
  const [reviewMessage, setReviewMessage] = useState("");
  const [rating, setRating] = useState(5);

  const [disabled, setDisabled] = useState(false);

  const addReview = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      await addDoc(collection(db, "reviews"), {
        TattooPhoto: tattooUrl,
        message: reviewMessage,
        name: name,
        rating: rating,
      });
    } catch (error) {
      alert(error);
    } finally {
      setReviewAddSection(!reviewAddSection);
    }
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  // Convert Image To Base 64 Start

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64Image = await convertBase64(file);
    setTattooUrl(base64Image);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // Convert Image To Base 64 End

  return (
    <div className="container-fluid mx-auto px-8 lg:px-10 flex  justify-between items-center text-white h-20 sticky top-0 bg-black shadow-md shadow-gray-900 z-10 ">
      {/* Desktop Nav Start */}
      <div className="header__left font-MarckScript text-3xl cursor-pointer">
        <Slide>
          <h1 className="text-[whitesmoke]">
            <a href="https://artefacttattoos.com">ArtefactTattoos</a>
          </h1>
        </Slide>
        <p className="font-finlandica text-sm">Studio and Academy</p>
      </div>

      <div className="header__right font-finlandica">
        <div className="hidden md:flex gap-10 desktop__nav">
          {NavLinks.map((navLink) => {
            return (
              <Slide direction="down">
                {navLink.click ? (
                  <div
                    className="flex gap-2 cursor-pointer hover:bg-slate-300 hover:text-black rounded p-2 transition "
                    onClick={() => {
                      setReviewAddSection(!reviewAddSection);
                    }}
                  >
                    <navLink.navIcon />
                    <span>{navLink.navText}</span>
                  </div>
                ) : (
                  <div className="flex gap-2 cursor-pointer hover:bg-slate-300 hover:text-black rounded p-2 transition">
                    <navLink.navIcon />
                    <a href={navLink.navLink} target="_blank" rel="noreferrer">
                      {navLink.navText}
                    </a>
                  </div>
                )}
              </Slide>
            );
          })}
        </div>
        {/* Desktop Nav End */}

        {/* HandBurger Menu Start */}
        <div
          className="mobile__nav cursor-pointer md:hidden"
          onClick={() => setToggle(!toggle)}
        >
          <Zoom>
            <MenuIcon />
          </Zoom>
        </div>
        {/* HandBurger Menu End */}
      </div>

      {/* Mobile Nav Start */}
      <Fade
        className={
          toggle
            ? "bg-gray-900 absolute top-20 left-0 mx-auto w-screen"
            : "hidden"
        }
      >
        <div className="flex flex-col justify-center items-center gap-5 m-5 font-finlandica">
          {NavLinks.map((navLink) => {
            return (
              <Slide direction="down">
                {navLink.click ? (
                  <div
                    className="flex gap-2 cursor-pointer hover:bg-slate-300 hover:text-black rounded p-2 transition"
                    onClick={() => {
                      setReviewAddSection(!reviewAddSection);
                    }}
                  >
                    <navLink.navIcon />
                    <span>{navLink.navText}</span>
                  </div>
                ) : (
                  <div className="flex gap-2 cursor-pointer hover:bg-slate-300 hover:text-black rounded p-2 transition">
                    <navLink.navIcon />
                    <a href={navLink.navLink} target="_blank" rel="noreferrer">
                      {navLink.navText}
                    </a>
                  </div>
                )}
              </Slide>
            );
          })}
        </div>
      </Fade>

      {/* Mobile Nav End */}

      {/* Review Add Card Start */}
      <div
        className={
          reviewAddSection
            ? "absolute top-20 left-0 right-0 bg-[whitesmoke] container mx-auto p-4 rounded-b-md"
            : "hidden"
        }
      >
        <Fade>
          <div className="flex flex-row-reverse justify-between items-center mt-[-5px] mb-4 text-black">
            <button
              onClick={() => setReviewAddSection(!reviewAddSection)}
              className="underline"
            >
              Close
            </button>
            <h1 className="text-2xl border-l-4 p-1 border-black font-finlandica">
              Drop Your Review
            </h1>
          </div>
          <form
            onSubmit={addReview}
            className="flex flex-col sm:flex-row gap-2"
          >
            <div className="flex flex-col gap-2 text-black ">
              <TextField
                id="outlined-basic"
                label="Enter Your Name"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                style={{ backgroundColor: "white", borderRadius: "2px" }}
                value={name}
                size="small"
                type="text"
                required
              />
              <div className="flex flex-col gap-2">
                <h2 className="border-l-4 p-1 border-black font-finlandica">
                  Upload Tattoo Image (less Then 1028kb/1mb)
                </h2>
                <input
                  required
                  type="file"
                  onChange={(e) => {
                    if (e.target.files[0].size > 1048487) {
                      alert("File is too big!");
                      e.target.value = "";
                    } else {
                      uploadImage(e);
                    }
                  }}
                />
              </div>
              <div className="flex items-center">
                <h2 className="border-l-4 p-1 border-black font-finlandica">
                  Give A Rating :-
                </h2>
                <ReactStars
                  count={5}
                  size={36}
                  value={rating}
                  onChange={ratingChanged}
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-2">
              <TextField
                id="outlined-multiline-flexible"
                label="Enter The Review Message"
                multiline
                maxRows={5}
                size="small"
                fullWidth
                style={{ backgroundColor: "white", borderRadius: "2px" }}
                type="text"
                onChange={(e) => setReviewMessage(e.target.value)}
                value={reviewMessage}
                required
              />
              <button
                type="submit"
                className={
                  disabled
                    ? "cursor-not-allowed w-full bg-blue-500 rounded-md p-2 text-white"
                    : "w-full bg-blue-500 rounded-md p-2 text-white"
                }
              >
                Submit Review
              </button>
            </div>
          </form>
        </Fade>
      </div>

      {/* Review Add Card End */}
    </div>
  );
};

export default Navbar;
