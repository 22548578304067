import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBwteFrRhfibqlz07Oba2cSkauZZ0aDhHE",
  authDomain: "artefacttattoos-studio.firebaseapp.com",
  projectId: "artefacttattoos-studio",
  storageBucket: "artefacttattoos-studio.appspot.com",
  messagingSenderId: "814245017736",
  appId: "1:814245017736:web:deb5870a27765ee656473d",
  measurementId: "G-YYJM1P5BG4",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const db = getFirestore(app);

export default app;
