import React from "react";
import "./App.css";
import Footer from "./components/Footer";
import Works from "./components/Works";
import Services from "./components/Services";
import About from "./components/About";
import Navbar from "./components/Navbar";
import YtVideos from "./components/YtVideos";
import Reviews from "./components/Reviews";
import ParticlesBackground from "./components/ParticlesBackground";
import IgPosts from "./components/IgPosts";

const App = () => {
  return (
    <div className="app">
      <ParticlesBackground />
      <Navbar />
      <About />
      <Services />
      <Works />
      <IgPosts />
      <YtVideos />
      <Reviews />
      <Footer />
    </div>
  );
};

export default App;
