import React, { useEffect, useState } from "react";
import Map from "./Map";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Zoom, Fade, AttentionSeeker } from "react-awesome-reveal";
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";

const SocialConnect = ({ Icon, link, color }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <Icon style={{ color: color }} />
    </a>
  );
};

// const SocialsData = [
//   {
//     socialColorCode: "#fe4164",
//     socialIcon: InstagramIcon,
//   },
//   {
//     socialColorCode: "#FF0000",
//     socialIcon: YouTubeIcon,
//   },
//   {
//     socialLink: "https://wa.me/message/PJMC2B7NV2HRC1",
//     socialColorCode: "#25D366",
//     socialIcon: WhatsAppIcon,
//   },
//   {
//     socialLink: "https://www.facebook.com/sujeshdancer",
//     socialColorCode: "lightblue",
//     socialIcon: FacebookIcon,
//   },
// ];

const Footer = () => {
  const [socialLinks1, setSocialLinks1] = useState([]);
  const [socialLinks2, setSocialLinks2] = useState([]);
  const [socialLinks3, setSocialLinks3] = useState([]);
  const [socialLinks4, setSocialLinks4] = useState([]);
  useEffect(() => {
    getDocs(collection(db, "socials")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const instagramLink = querySnapshot.docs.map(
          (doc) => doc.data().instagram
        );
        const youtubeLink = querySnapshot.docs.map((doc) => doc.data().youtube);
        const whatsappLink = querySnapshot.docs.map(
          (doc) => doc.data().whatsapp
        );
        const facebookLink = querySnapshot.docs.map(
          (doc) => doc.data().facebook
        );
        setSocialLinks1(instagramLink);
        setSocialLinks2(youtubeLink);
        setSocialLinks3(whatsappLink);
        setSocialLinks4(facebookLink);
      });
    });
  }, []);
  return (
    <div
      className="footer container-fluid mx-auto px-4 flex flex-col justify-center 
    items-center gap-5 text-center text-white shadow-md font-MarckScript"
    >
      <Fade>
        <div className="mt-10 mb-5 text-3xl studio__name opacity-100 font-marker">
          Contact Us
        </div>
      </Fade>
      <Fade>
        <div className="studio__number text-2xl opacity-90 font-finlandica">
          +91 9535532412 , 7090066667
        </div>
      </Fade>
      <div className="socials flex gap-5 opacity-90">
        <AttentionSeeker effect="heartBeat" delay={2000}>
          {socialLinks1.map((link) => {
            return (
              <SocialConnect
                key={link}
                link={link}
                color="#fe4164"
                Icon={InstagramIcon}
              />
            );
          })}
          {socialLinks2.map((link) => {
            return (
              <SocialConnect
                key={link}
                link={link}
                color="#FF0000"
                Icon={YouTubeIcon}
              />
            );
          })}
          {socialLinks3.map((link) => {
            return (
              <SocialConnect
                key={link}
                link={link}
                color="#25D366"
                Icon={WhatsAppIcon}
              />
            );
          })}
          {socialLinks4.map((link) => {
            return (
              <SocialConnect
                key={link}
                link={link}
                color="lightblue"
                Icon={FacebookIcon}
              />
            );
          })}
        </AttentionSeeker>
      </div>
      <div className="studio__address opacity-90">
        #6/1, 1st floor, 20th main, BTM 1st stage, Maruthinagar main road, Bengluru, Karnataka-560068
      </div>
      <Zoom>
        <div className="studio__map">
          <Map />
        </div>
      </Zoom>
      <div className="mt-5 copyright opacity-90">
        <p className="text-gray-400 text-md">
          @2023 artefacttattoos | All rights reserved | Website Designed by{" "}
          <a href="https://dewebdev.netlify.com" className="text-white">
            dewebdev Creations
          </a>{" "}
          and{" "}
          <a
            href="https://linkedin.com/in/tushar-prabhu01"
            className="text-white"
            target="_blank" rel="noreferrer"
          >
            Tushar
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
