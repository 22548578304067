import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import ReactStars from "react-stars";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const getReviews = async () => {
      const data = await getDocs(collection(db, "reviews"));
      setReviews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getReviews();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="container mx-auto px-10 ">
      <h1 className="m-10 text-3xl text-white font-marker text-center">
        Reviews
      </h1>
      <Carousel
        swipeable={true}
        draggable={true}
        responsive={responsive}
        rewindWithAnimation={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {reviews.map((review) => {
          return (
            <div key={review.id} className="relative w-80 h-80 mx-auto">
              <img
                src={review.TattooPhoto}
                alt={review.name}
                className="rounded-md h-full w-full object-cover"
              />
              <div className="absolute top-0 w-full h-full p-4 overflow-auto rounded-md bg-gray-300 opacity-80 sm:opacity-0 hover:opacity-100 transition-all ease-in duration-300">
                <h1 className="text-2xl">{review.name}</h1>
                <br></br>
                <p className="break-words mb-2">{review.message}</p>
                <ReactStars
                  count={5}
                  value={review.rating}
                  size={24}
                  color2={"black"}
                  edit={false}
                />
              </div>
              {/* <p>{console.log(review.Visited)}</p> */}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Reviews;
