import React from "react";

export default function ServicesCard(props) {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        className="w-[300px] h-[300px] object-cover rounded-full"
        src={props.img}
        alt={props.text}
      />
      <div className="px-6 py-4">
        <div className="font-bold text-xl opacity-80 font-MarckScript mb-2 text-white">
          {props.text}
        </div>
        {/* <p className="text-gray-700 text-base">{props.text}</p> */}
      </div>
      {/* <div className="px-6 pt-4 pb-2">
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          #photography
        </span>
      </div> */}
    </div>
  );
}
