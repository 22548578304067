import React from "react";
import InkBtn from "../assests/images/inkButton.png";
import { Zoom, Rotate, Fade } from "react-awesome-reveal";

const About = () => {
  return (
    <Fade>
      <div className="about md:bg-center flex flex-col justify-center items-center text-white about text-center gap-10 px-4 lg:h-[87vh]">
        <Fade>
          <h1 className="text-3xl mt-10 lg:mt-0 font-marker ">About Us</h1>
        </Fade>
        <Zoom>
          <p className="sm:w-[40vw] text-lg font-MarckScript opacity-90">
            Get inked with artefact tattoos and we have professional and
            experienced artists , make your dreams tatted on your skin which is
            100% hygienic and tidy environment also with the minimal pain.
          </p>
        </Zoom>
        <Rotate>
          <div className="relative mb-10  cursor-pointer animate-pulse">
            <img src={InkBtn} alt="Book an Appointment" className="h-26 w-72" />
            <h2 className="absolute top-[1.2rem] left-16 text-black opacity-90 text-md font-marker">
              Book an Appointment
            </h2>
          </div>
        </Rotate>
      </div>
    </Fade>
  );
};

export default About;
