import React from "react";

const Map = () => {
  return (
    <div className="container mx-auto px-4 w-screen lg:w-[50vw]">
      <iframe
        className="h-[14rem] w-full rounded-md"
        style={{ border: "0px" }}
        title="gmap_canvas"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.7766013658043!2d77.61149811112215!3d12.922074987336199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150046054c07%3A0x5710b4dc39baa414!2sArtefacttattoos%20studio%20and%20academy!5e0!3m2!1sen!2sin!4v1722356267063!5m2!1sen!2sin"
        width="400"
        height="300"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
