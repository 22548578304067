import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { Zoom, Fade } from "react-awesome-reveal";

function IgPosts() {
const [postLinks, setPostLinks] = useState([]);

  useEffect(() => {
    getDocs(collection(db, "ig_posts")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(doc.data().url);
        const links = querySnapshot.docs.map((doc) => doc.data().url);
        setPostLinks(links);
      });
    });
  }, []);

  return (
    <div>
      <Fade>
        <Zoom>
          <h1 className="m-10 text-3xl text-white font-marker text-center">
            Our Instagram
          </h1>
        </Zoom>

        <div className="grid md:grid-cols-2 lg:grid-cols-3">
          {postLinks.map((link) => (
            <div className="m-12 md:my-5 md:mx-10 lg:mx-20">
              <iframe
                className="col-span-2 rounded-md"
                height="537px"
                title={link}
                src={`${link}embed`}
              />
            </div>
          ))}
        </div>

        {/* .split("/").pop() */}
      </Fade>
    </div>
  );
}

export default IgPosts;
