import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ServicesCard from "./ServicesCard";
import eyebrow from "../assests/images/eyebrow.jpg";
import lipTattoo from "../assests/images/lipTattoo.jpg";
import piercing from "../assests/images/piercing.jpg";
import tattoo from "../assests/images/tattoo.jpeg";
import { Zoom, Fade } from "react-awesome-reveal";

const Services = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 810,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const servicesData = [
    {
      serviceName: "Eyebrow Fillers",
      serviceImage: eyebrow,
    },
    {
      serviceName: "Tattoos",
      serviceImage: tattoo,
    },
    {
      serviceName: "Lips Tattoos",
      serviceImage: lipTattoo,
    },
    {
      serviceName: "Piercing",
      serviceImage: piercing,
    },
  ];

  return (
    <div className="container-fluid mx-auto px-6 text-center lg:w-[45rem] lg:h-[80vh]">
      <Fade>
        <h2 className="m-10 text-3xl text-white font-marker">Services</h2>
      </Fade>
      <Zoom>
        <Slider {...settings} className="m-2">
          {servicesData.map((service) => {
            return (
              <ServicesCard
                key={service.serviceName}
                img={service.serviceImage}
                text={service.serviceName}
              />
            );
          })}
        </Slider>
      </Zoom>
    </div>
  );
};

export default Services;
