import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { Zoom, Fade } from "react-awesome-reveal";
import "./CustomStyles/style.css";

function YtVideos() {
  const [videos, setvideos] = useState([]);

  useEffect(() => {
    getDocs(collection(db, "videos")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(doc.data().url);
        const links = querySnapshot.docs.map((doc) => doc.data().url);
        setvideos(links);
      });
    });
  }, []);

  return (
    <div>
      <Zoom>
        <Fade>
          <h1 className="m-10 text-3xl text-white font-marker text-center">
            Our YouTube
          </h1>

          {videos.map((link) => (
            // <iframe key={link} src={link} title={link} />
            <div className="m-5 md:mx-40 md:my-20 lg:mx-60">
              <div className="video-responsive">
                <iframe className="rounded-md"
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${link}`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />
              </div>
            </div>
          ))}
        </Fade>
      </Zoom>
    </div>
  );
}

export default YtVideos;
