import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Tattoo1 from "../assests/images/tattoo1.jpg";
import Tattoo2 from "../assests/images/tattoo2.jpg";
import Tattoo3 from "../assests/images/tattoo3.JPG";
import Tattoo4 from "../assests/images/tattoo4.JPG";
import Tattoo5 from "../assests/images/tattoo5.JPG";
import { Zoom, Fade } from "react-awesome-reveal";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {
  return (
    <Zoom>
      <Fade>
        <h1 className="m-10 text-3xl text-white font-marker text-center">
          Recent Works
        </h1>
      </Fade>
      <div className="works flex flex-col items-center justify-center h-screen lg:h-[86.5vh]">
        <ImageList
          className="lg:w-[45vw] rounded-md"
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {itemData.map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </Zoom>
  );
}

const itemData = [
  {
    img: Tattoo2,
    title: "",
    rows: 3,
    cols: 2,
  },
  {
    img: Tattoo3,
    title: "",
    rows: 2,
    cols: 2,
  },
  {
    img: Tattoo1,
    title: "",
    rows: 4,
    cols: 2,
  },
  {
    img: Tattoo4,
    title: "",
    rows: 3,
    cols: 2,
  },
  {
    img: Tattoo5,
    title: "",
    rows: 2,
    cols: 4,
  },
];
